<template>
  <div class="container">
    <div class="place">
      <div class="pay-box pay-info">
        <div class="rest-box">
          <div class="pay-status">
            <i class="iconfont icon-chenggong" v-if="orderData.pay_status == 1"></i>
            <i class="iconfont icon-shibai" v-else></i>
          </div>
        </div>
        <div class="main-box">
          <h3 class="info-title">{{orderData.pay_status_msg}}</h3>
          <template v-for="(item,index) in page_options.text_list">
            <p class="info-descriptions" :key="index" v-html="item"></p>
          </template>
        </div>
      </div>
      <!-- <div class="pay-box pay-control">
        <div class="rest-box"></div>
        <div class="main-box">
          <ul class="control-list">
            <li class="cl-item">
              <el-button class="fake-btn" @click="lookingOrderFun">
                <span>查看订单</span>
              </el-button>
            </li>
            <li class="cl-item">
              <el-button class="fake-btn" @click="goToHomePage">
                <span>返回首页</span>
              </el-button>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'SucceedCommonPay',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    textList: {
      type: Array,
      default: () => [],
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      page_options: { // 页面信息参数
        order_id: '',
        text_list: [], // 文本
      },
      orderData: {}, // 订单信息
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 获取路由信息
      this.getRouterInfoFun().then(res => {
        console.log('获取路由信息 res == ',res)
        let {query} = res;
        if(query.data) {
          let resData = JSON.parse(decodeURIComponent(query.data));
          console.log('resData == ',resData)
          let { order_id, order_type } = resData;
          this.page_options = Object.assign(this.page_options,resData);
          console.log('this.page_options == ',this.page_options)
          this.getOrderInfoFun(order_id,order_type);
        } else {
          // 404
        }
      })
    },
    /**
     * 获取订单信息
     */
    getOrderInfoFun(order_id,order_type) {
      this.myRequest({
        url: '/api/order/orderpaystatus',
        data: {
          id: order_id,
          type: order_type,
        }
      }).then(res => {
        console.log('获取订单信息 res == ',res);
        this.orderData = res.data.data;
      })
    },
    /**
     * 跳转首页
     */
    goToHomePage() {
      this.myRouterPush({
        path: '/insurance?id=1',
      })
    },
    /**
     * 获取路由信息
     */
    getRouterInfoFun() {
      return this.myGetRouter()
      // .then(res => {
      //   console.log('获取路由信息 res == ',res)
      //   // let { order_id } = res.query;
      //   this.orderData = res.query
      //   console.log('获取路由信息 res == ',res)
      //   resolve(res.query);
      // })
    },
    /**
     * 查看订单
     */
    lookingOrderFun() {
      let { order_id } = this.page_options;
      this.myRouterPush({
        path: `/insurance/query/index?id=${order_id}`,
      })
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f5f5f5;
  padding-top: 30px;
  .place {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 14px -21px rgba(0,0,0,.6);
    .pay-box {
      display: flex;
      .rest-box {
        width: 42px;
        margin-right: 20px;
        .pay-status {
          height: 42px;
          .iconfont {
            font-size: 42px;
            color: #E62129;
          }
        }
      }
      .main-box {
        flex: 1;
        min-width: 0;
      }
      &.pay-info {
        .info-title {
          margin-top: 6px;
          font-weight: 700;
          font-size: 22px;
          color: #444343;
        }
        .info-descriptions {
          margin-top: 5px;
          font-size: 14px;
          color: #444343;
          &:first-of-type {
            margin-top: 16px;
          }
        }
        .main-box {
        }
      }
      &.pay-control {
        box-sizing: border-box;
        padding-top: 35px;
        margin-top: 35px;
        border-top: 1px solid #CCCCCC;
        .control-list {
          display: flex;
          .cl-item {
            margin-left: 30px;
            &::v-deep {
              .fake-btn {
                width: 100%;
                height: 36px;
                padding: 0 15px;
                font-size: 16px;
                color: #787878;
                border: 1px solid #CCCCCC;
                background: #fff;
                outline: none;
                &.el-button:hover,&.el-button:focus,&.el-button:active {
                  color: none;
                  border-color: none;
                  background-color: none;
                }
                &.el-button:active {
                  color: $active-default-color;
                  border-color: $active-default-color;
                  background-color: #fff;
                }
              }
            }
            &:first-of-type {
              margin-left: 0;
              &::v-deep {
                .fake-btn {
                  color: #fff;
                  border: 1px solid #E62129;
                  background: #E62129;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
